.MuiDrawer-paper {
  top: 0;
  flex: 1 0 auto;
  height: 100%;
  display: flex;
  outline: 0;
  z-index: 1200;
  position: fixed;
  overflow-y: auto;
  background-color: #e6eaff;
  color: #233044;
  /* flex-direction: column; */
  /* -webkit-overflow-scrolling: touch; */
}
.MuiButton-containedSecondary {
  color: #233044;
  background-color: #f7f9fc;
  border: 1px solid #233044;
  border-radius: 2px;
}
.MuiButton-containedSecondary:hover {
  color: #233044;
  background-color: #f7f9fc;
  border: 1px solid #233044;
  border-radius: 2px;
}
.MuiButton-outlined {
  color: rgba(0, 0, 0, 0.87);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  background-color: #233044;
  color: white;
  width: 199px;
  height: 38px;
}
.MuiButton-outlined:hover {
    color: rgba(0, 0, 0, 0.87);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
    background-color: #233044;
    color: white;
    width: 199px;
    height: 38px;
  }
.mat-cell {
  font-size: 12px;
  font-family: Verdana, Sans-Serif;
  display: table-cell;
  height: 48px;
  vertical-align: middle;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-left: 2px solid rgba(0, 0, 0, 0.12);
  border-right: 2px solid rgba(0, 0, 0, 0.12);
}
.MuiTableRow-root {
  color: inherit;
  color: #233044;
  outline: 0;
  vertical-align: middle;
}
.cTDYPz > input {
  color: blue;
  /* padding-top: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  padding-left: 48px;
  width: 160px; */
}
/* .MuiInputBase-input {
  font: inherit;
  color: currentColor;
  width: 100%;
  border: 0;
  border: 1 px solid black;
  height: 1.1876 em;
  margin: 0;
  display: block;
  padding: 9 px 0 px 0 11 px;
  min-width: 0;
  background: none;
  box-sizing: content-box;
  animation-name: mui-auto-fill-cancel;
  letter-spacing: inherit;
  animation-duration: 10ms;
  -webkit-tap-highlight-color: transparent;
} */
/* width */
::-webkit-scrollbar {
  width: 15px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 6px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(17,24,39); 
  border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(17,24,39); 
}
.MuiSelect-select {
  cursor: pointer;
  min-width: 16px;
  user-select: none;
  border: 1px solid #cdcaca;
  border-radius: 3px;
 -moz-appearance: none;
  -webkit-appearance: none;
}
.MuiSelect-select::placeholder {
  margin-left: 20px;
}
.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #F7F9FC;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.btn-secondary {
  margin-left: 3px;
  background-color: #F7F9FC;
  color: #233044;
  border: 1px solid #233044;
  padding:0.395rem 5.75rem;
}
.btn-secondary:hover {
  margin-left: 3px;
  background-color: #F7F9FC;
  color: #233044;
  border: 1px solid #233044;
  padding:0.395rem 5.75rem;
}
.btn-success:last-child{
  margin-top:10px;
}
.first-primary{
  background-color: #233044;
  color: white;
  border: 1px solid white;
  padding:0.395rem 4.25rem;
}
.first-primary:hover{
  background-color: #233044;
  color: white;
  border: 1px solid white;
  padding:0.395rem 4.25rem;
}

.cursor-pointer{
  cursor: pointer;
}

 
