.form-control {
    display: block;
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: #495057;
    background-color: #F7F9FC;
    background-clip: padding-box;
    border: 1px solid #233044;
    border-radius: 0.25rem;
    /* transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out; */
}
.form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #233044;
    opacity: 1; /* Firefox */
}
.btn-secondary {
    margin-left: 3px;
    background-color: #F7F9FC;
    color: #233044;
    border: 1px solid #233044;
    padding:0.395rem 5.75rem;
}
.btn-secondary:hover {
    margin-left: 3px;
    background-color: #F7F9FC;
    color: #233044;
    border: 1px solid #233044;
    padding:0.395rem 5.75rem;
}
.first-primary{
    background-color: #233044;
    color: white;
    border: 1px solid white;
    padding:0.395rem 4.25rem;
}
.first-primary:hover{
    background-color: #233044;
    color: white;
    border: 1px solid white;
    padding:0.395rem 4.25rem;
}
.add_new{
    background-color: #233044;
}
